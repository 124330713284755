<template>
  <el-container class="list">
    <avue-crud ref="crud"
               style="display: none;"
               :option="option"
               v-model="form"
               :page.sync="page"
               @row-save="rowSave"
               @row-update="rowUpdate"
               @row-del="rowDel"
               @on-load="onLoad"
               v-loading="loading"
               v-bind="$loadingParams"
               :data="data">
      <template slot="contentForm">
        <monaco-editor v-model="form.content"
                       :language="javascript"
                       :height="400"></monaco-editor>
      </template>
    </avue-crud>
    <el-header class="content__header">
      <div class="content__box content__nav">
        <div class="content__add"
             v-if="menu"
             @click="$refs.crud.rowAdd()">
          <img :src="`${$router.options.base}img/fun.png`"
               height="40px"
               alt="">
          <div>
            <p>创建代码</p>
            <span>公共代码 化繁为简</span>
          </div>
        </div>
        <div class="content__page">

          <div class="list-search">
            <el-input v-model="search.name"
                      size="small"
                      @keyup.enter.native="onLoad"
                      placeholder="请输入名称">
              <i slot="suffix"
                 @click="onLoad"
                 class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <el-pagination v-if="page.total>0"
                         layout="total, prev, pager, next"
                         background
                         size="small"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :page-size="page.pageSize"
                         :current-page.sync="page.currentPage"
                         :total="page.total">
          </el-pagination>
        </div>
      </div>
      <el-tabs class="list-tabs"
               v-model="active"
               @tab-click="handleTabClick">
        <el-tab-pane v-for="(item,index) in dicData"
                     :key="index"
                     :label="item.label"
                     :name="item.value"></el-tab-pane>
      </el-tabs>
    </el-header>
    <el-main class="content"
             v-loading="loading"
             v-bind="$loadingParams">
      <div class="content__box">
        <template v-if="data.length>0">
          <div class="content__item"
               v-for="(item,index) in data"
               @mouseenter="item._menu=true"
               @mouseleave="item._menu=false"
               :key="index">
            <div class="content__info">
              <div class="content__code">
                <avue-highlight :height="150"
                                style="width:100%"
                                v-model="item.content"></avue-highlight>
              </div>

              <div class="content__menu"
                   v-if="item._menu">
                <div class="content__right">

                </div>
                <div class="content__start">
                  <template v-if="menu">
                    <div class="content__btn"
                         @click="handleEdit(item,index)">
                      <i class="el-icon-edit-outline"></i>
                    </div>
                    <div class="content__btn"
                         @click="rowDel(item,index)">
                      <i class="el-icon-delete"></i>
                    </div>
                  </template>
                  <div v-else
                       class="content__btn"
                       @click="handleRow(item,index)">
                    <i class="el-icon-check"></i>
                  </div>
                </div>

              </div>
            </div>
            <div class="content__main">
              <span class="content__name">{{item.name}}</span>
              <span class="content__status"> {{ item.username }}</span>
            </div>
          </div>
        </template>
        <el-empty v-else
                  class="content__empty"
                  description="暂无数据">
          <template #image>
            <svg-icon icon-class="empty" />
          </template>
        </el-empty>
      </div>
    </el-main>
  </el-container>
</template>
  
  <script>

import MonacoEditor from '@avue/avue-data/page/components/monaco-editor'
import { getList, addObj, delObj, updateObj } from '@avue/avue-data/api/fun'
import { dicOption } from '@avue/avue-data/option/config';
export default {
  props: {
    menu: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MonacoEditor
  },
  data () {
    return {
      dicData: dicOption.funType,
      search: {},
      loading: false,
      active: 0,
      form: {},
      data: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        dialogWidth: '800',
        dialogMenuPosition: 'center',
        height: 'auto',
        calcHeight: 140,
        header: false,
        index: true,
        align: 'center',
        headerAlign: 'center',
        menu: this.menu,
        column: [
          {
            label: '名称',
            prop: 'name',
            rules: [{
              required: true,
              message: "请输入名称",
              trigger: "blur"
            }]
          },
          {
            label: '分类',
            prop: 'type',
            type: 'select',
            dataType: 'string',
            dicData: dicOption.funType,
            rules: [{
              required: true,
              message: "请选择分类",
              trigger: "blur"
            }]
          },
          {
            label: '代码',
            prop: 'content',
            type: 'textarea',
            overHidden: true,
            span: 24,
            rules: [{
              required: true,
              message: "请输入代码",
              trigger: "blur"
            }]
          }
        ]
      }
    }
  },
  methods: {
    handleRow (row, index) {
      this.$emit('change', row)
    },
    handleCurrentChange (val) {
      this.page.currentPage = val;
      this.onLoad();
    },
    handleSizeChange (val) {
      this.page.pageSize = val;
      this.onLoad();
    },
    vaildData (id) {
      return [0, 1, 2].includes(id)
    },
    handleEdit (row, index) {
      this.$refs.crud.rowEdit(row, index);
    },
    rowDel (row, index) {
      if (this.vaildData(index) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delObj(row.id).then(() => {
          this.$message.success('删除成功');
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    rowUpdate (row, index, done, loading) {
      if (this.vaildData(index) && this.$website.isDemo) {
        done();
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      updateObj(row).then(() => {
        done();
        this.$message.success('修改成功');
        this.onLoad()
      }).catch(err => {
        loading()
      })
    },
    rowSave (row, done, loading) {
      addObj(row).then(() => {
        this.$message.success('新增成功');
        this.onLoad()
        done();
      }).catch(err => {
        loading()
      })
    },

    handleTabClick () {
      this.page.page = 1
      this.onLoad()
    },
    onLoad () {
      this.loading = true
      getList({
        type: this.active,
        name: this.search.name,
        current: 1,
        size: 100,
      }).then(res => {
        this.loading = false
        const data = res.data.data;
        let records = data.records
        records.forEach(ele => ele._menu = false);
        this.page.total = data.total;
        this.data = records;
      })
    }
  }
}
  </script>
  
  <style lang="scss" scoped>
</style>